<template>
  <div class="form-title">
    <h3 class="ft-title">{{title}}</h3>
    <p class="ft-descriptions">
      <span>{{descriptions}}</span>
      <span class="diff">{{title}}</span>
      <span>{{text}}</span>
    </p>
  </div>
</template>

<script>
export default {
  // 允许组件模板递归地调用自身
  name: 'FormTitle',
  // 声明一组可用于组件实例中的组件
  components: {

  },
  // 一个用于从父组件接收数据的数组或对象
  props: {
    title: {
      type: String,
      default: '这是标题',
    },
    descriptions: {
      type: String,
      default: 'This Is Title English Word',
    },
    text: {
      type: String,
      default: 'Text',
    }
  },
  // 该函数返回组件实例的 data 对象
  data () {
    return {

    }
  },
  // 计算属性：
  computed: {

  },
  // 钩子函数--侦听data变动：
  watch: {

  },
  // 在实例创建完成后被立即同步调用
  methods: {

  },
  // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
  created() {

  },
  // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
  mounted() {

  },
  beforeCreate() { // 创建前

  },
  beforeMount() { // 挂载前

  },
  beforeUpdate() { // 更新前

  },
  updated() { // 更新后

  },
  beforeDestroy() { // 销毁前

  },
  destroyed() { // 销毁后

  },
  activated() { // 缓存保持，需要由 keep-alive 触发

  },
}
</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
.form-title {
  $line-height: 35px;
  position: relative;
  height: $line-height;
  line-height: $line-height;
  min-width: 1000px;
  // overflow: hidden;
  margin: 0 auto;
  color: #444343;
  text-align: center;
  .ft-title {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 22px;
  }
  .ft-descriptions {
    font-size: 42px;
    opacity: .1;
    white-space: nowrap;
    .diff {
      font-size: 28px;
      opacity: 0;
    }
  }
}
</style>
